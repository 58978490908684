export const CHECK_CLIENT_REQUEST = "CHECK_CLIENT_REQUEST";
export const CHECK_CLIENT_SUCCESS = "CHECK_CLIENT_SUCCESS";
export const CHECK_CLIENT_FAILURE = "CHECK_CLIENT_FAILURE";

export const CHECK_DEPARTMENT_REQUEST = "CHECK_DEPARTMENT_REQUEST";
export const CHECK_DEPARTMENT_SUCCESS = "CHECK_DEPARTMENT_SUCCESS";
export const CHECK_DEPARTMENT_FAILURE = "CHECK_DEPARTMENT_FAILURE";

export const CHECK_MEMBER_REQUEST = "CHECK_MEMBER_REQUEST";
export const CHECK_MEMBER_SUCCESS = "CHECK_MEMBER_SUCCESS";
export const CHECK_MEMBER_FAILURE = "CHECK_MEMBER_FAILURE";

export const CHECK_ALL_MEMBER_REQUEST = "CHECK_ALL_MEMBER_REQUEST";
export const CHECK_ALL_MEMBER_SUCCESS = "CHECK_ALL_MEMBER_SUCCESS";
export const CHECK_ALL_MEMBER_FAILURE = "CHECK_ALL_MEMBER_FAILURE";

export const UN_CHECK_ALL_MEMBER_REQUEST = "UN_CHECK_ALL_MEMBER_REQUEST";
export const UN_CHECK_ALL_MEMBER_SUCCESS = "UN_CHECK_ALL_MEMBER_SUCCESS";
export const UN_CHECK_ALL_MEMBER_FAILURE = "UN_CHECK_ALL_MEMBER_FAILURE";

export const CHECK_PROJECT_REQUEST = "CHECK_PROJECT_REQUEST";
export const CHECK_PROJECT_SUCCESS = "CHECK_PROJECT_SUCCESS";
export const CHECK_PROJECT_FAILURE = "CHECK_PROJECT_FAILURE";

export const CHECK_PROGRAM_REQUEST = "CHECK_PROGRAM_REQUEST";
export const CHECK_PROGRAM_SUCCESS = "CHECK_PROGRAM_SUCCESS";
export const CHECK_PROGRAM_FAILURE = "CHECK_PROGRAM_FAILURE";

export const CHECK_PORTFOLIO_REQUEST = "CHECK_PORTFOLIO_REQUEST";
export const CHECK_PORTFOLIO_SUCCESS = "CHECK_PORTFOLIO_SUCCESS";
export const CHECK_PORTFOLIO_FAILURE = "CHECK_PORTFOLIO_FAILURE";

export const CLEAR_ALL_REQUEST = "CLEAR_ALL_REQUEST";
export const CLEAR_ALL_SUCCESS = "CLEAR_ALL_SUCCESS";
export const CLEAR_ALL_FAILURE = "CLEAR_ALL_FAILURE";

export const CHECK_PMO_DELIVERABLE_REQUEST = "CHECK_PMO_DELIVERABLE_REQUEST";
export const CHECK_PMO_DELIVERABLE_SUCCESS = "CHECK_PMO_DELIVERABLE_SUCCESS";
export const CHECK_PMO_DELIVERABLE_FAILURE = "CHECK_PMO_DELIVERABLE_FAILURE";

export const CHECK_PMO_PROJECT_REQUEST = "CHECK_PMO_PROJECT_REQUEST";
export const CHECK_PMO_PROJECT_SUCCESS = "CHECK_PMO_PROJECT_SUCCESS";
export const CHECK_PMO_PROJECT_FAILURE = "CHECK_PMO_PROJECT_FAILURE";

export const CHECK_PMO_PROGRAM_REQUEST = "CHECK_PMO_PROGRAM_REQUEST";
export const CHECK_PMO_PROGRAM_SUCCESS = "CHECK_PMO_PROGRAM_SUCCESS";
export const CHECK_PMO_PROGRAM_FAILURE = "CHECK_PMO_PROGRAM_FAILURE";

export const CHECK_PMO_PORTFOLIO_REQUEST = "CHECK_PMO_PORTFOLIO_REQUEST";
export const CHECK_PMO_PORTFOLIO_SUCCESS = "CHECK_PMO_PORTFOLIO_SUCCESS";
export const CHECK_PMO_PORTFOLIO_FAILURE = "CHECK_PMO_PORTFOLIO_FAILURE";

export const MODIFY_TEMPLATE_REQUEST = "MODIFY_TEMPLATE_REQUEST";
export const MODIFY_TEMPLATE_SUCCESS = "MODIFY_TEMPLATE_SUCCESS";
export const MODIFY_TEMPLATE_FAILURE = "MODIFY_TEMPLATE_FAILURE";

export const MODIFY_WORKSTREAM_REQUEST = "MODIFY_WORKSTREAM_REQUEST";
export const MODIFY_WORKSTREAM_SUCCESS = "MODIFY_WORKSTREAM_SUCCESS";
export const MODIFY_WORKSTREAM_FAILURE = "MODIFY_WORKSTREAM_FAILURE";
