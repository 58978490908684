import axios from "../../api/axiosConfig";

//get workstreams
export const getWorkStreams = (data) => {
  return axios.post(`/workStream/get-workstreams/${data.adminId}`, data.data);
};

//add project
export const addWorkstream = (data) => {
  return axios.post(`/workStream/add-workstream/${data.id}`, data.data);
};

//edit project
export const editWorkstream = (data) => {
  return axios.patch(`/workStream/edit-workstream/${data.id}`, data.data);
};

//activate workstrem
export const activateWorkStream = (data) => {
  return axios.patch(`/workStream/activate/${data.id}`);
};

//de-activate workstream
export const deActivateStream = (data) => {
  return axios.patch(`/workStream/de-activate/${data.id}`);
};

//quickstart workstream
export const quickstartWorkstream = (data) => {
  return axios.patch(`/workStream/quickstart/${data.id}`, data.data);
};
