import * as types from "./types";

const initState = {
  loading: false,
  project: [],
  archiveProject: [],
  program: [],
  archiveProgram: [],
  portfolio: [],
  archivePortfolio: [],
  success: false,
};

const workStreamReducer = (state = initState, action) => {
  switch (action.type) {
    //get workstreams
    case types.GET_WORKSTREAMS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_WORKSTREAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        project: action.payload.data.filter(
          (elem) => elem.type == "project" && elem.status == "Active"
        ),
        program: action.payload.data.filter(
          (elem) => elem.type == "program" && elem.status == "Active"
        ),
        portfolio: action.payload.data.filter(
          (elem) => elem.type == "portfolio" && elem.status == "Active"
        ),
        archiveProject: action.payload.data.filter(
          (elem) => elem.type == "project" && elem.status == "De-Activate"
        ),
        archiveProgram: action.payload.data.filter(
          (elem) => elem.type == "program" && elem.status == "De-Activate"
        ),
        archivePortfolio: action.payload.data.filter(
          (elem) => elem.type == "portfolio" && elem.status == "De-Activate"
        ),
      };
    case types.GET_WORKSTREAMS_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add project
    case types.POST_WORKSTREAM_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.POST_WORKSTREAM_SUCCESS:
      let proj = action.payload.filter((elem) => elem.type == "project");
      let prog = action.payload.filter((elem) => elem.type == "program");
      let port = action.payload.filter((elem) => elem.type == "portfolio");
      window.location.reload();
      return {
        ...state,
        loading: false,
        success: true,
        project: [...state.project, ...proj],
        program: [...state.program, ...prog],
        portfolio: [...state.portfolio, ...port],
      };
    case types.POST_WORKSTREAM_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //edit project
    case types.EDIT_WORKSTREAM_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.EDIT_WORKSTREAM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        project:
          action.payload.type == "project"
            ? state?.project.map((item) =>
                item?._id == action.payload?._id ? action.payload : item
              )
            : state.project,
        program:
          action.payload.type == "program"
            ? state?.program.map((item) =>
                item?._id == action.payload?._id ? action.payload : item
              )
            : state.program,
        portfolio:
          action.payload.type == "portfolio"
            ? state?.portfolio.map((item) =>
                item?._id == action.payload?._id ? action.payload : item
              )
            : state.portfolio,
      };
    case types.EDIT_WORKSTREAM_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //activate - request
    case types.ACTIVATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ACTIVATE_SUCCESS:
      //;
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.ACTIVATE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //de-activate - request
    case types.DE_ACTIVATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.DE_ACTIVATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.DE_ACTIVATE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //quickstart - request
    case types.WORKSTREAM_QUICKSTART_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.WORKSTREAM_QUICKSTART_SUCCESS:
      //;
      return {
        ...state,
        loading: false,
        success: true,
        project: [...state.project, ...action.payload.project],
        program: [...state.program, ...action.payload.program],
        portfolio: [...state.portfolio, ...action.payload.portfolio],
      };
    case types.WORKSTREAM_QUICKSTART_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    default:
      return state;
  }
};

export default workStreamReducer;
