export const GET_WORKSTREAMS_REQUEST = "GET_WORKSTREAMS_REQUEST";
export const GET_WORKSTREAMS_SUCCESS = "GET_WORKSTREAMS_SUCCESS";
export const GET_WORKSTREAMS_FAILURE = "GET_WORKSTREAMS_FAILURE";

export const POST_WORKSTREAM_REQUEST = "POST_WORKSTREAM_REQUEST";
export const POST_WORKSTREAM_SUCCESS = "POST_WORKSTREAM_SUCCESS";
export const POST_WORKSTREAM_FAILURE = "POST_WORKSTREAM_FAILURE";

export const EDIT_WORKSTREAM_REQUEST = "EDIT_WORKSTREAM_REQUEST";
export const EDIT_WORKSTREAM_SUCCESS = "EDIT_WORKSTREAM_SUCCESS";
export const EDIT_WORKSTREAM_FAILURE = "EDIT_WORKSTREAM_FAILURE";

export const DE_ACTIVATE_REQUEST = "DE_ACTIVATE_REQUEST";
export const DE_ACTIVATE_SUCCESS = "DE_ACTIVATE_SUCCESS";
export const DE_ACTIVATE_FAILURE = "DE_ACTIVATE_FAILURE";

export const ACTIVATE_REQUEST = "ACTIVATE_REQUEST";
export const ACTIVATE_SUCCESS = "ACTIVATE_SUCCESS";
export const ACTIVATE_FAILURE = "ACTIVATE_FAILURE";

export const WORKSTREAM_QUICKSTART_REQUEST = "WORKSTREAM_QUICKSTART_REQUEST";
export const WORKSTREAM_QUICKSTART_SUCCESS = "WORKSTREAM_QUICKSTART_SUCCESS";
export const WORKSTREAM_QUICKSTART_FAILURE = "WORKSTREAM_QUICKSTART_FAILURE";
