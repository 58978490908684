/* eslint-disable */
import { put, call, takeLatest, select } from "redux-saga/effects";
import * as types from "./types";

//get activities
function* modifyActivityIdle(action) {
  try {
    yield put({
      type: types.ACTIVITY_MODIFY_IDLE_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.ACTIVITY_MODIFY_IDLE_FAILURE,
      payload: error,
    });
  }
}

//clear activities
function* clearActivityIdle(action) {
  try {
    yield put({
      type: types.CLEAR_IDLE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.CLEAR_IDLE_FAILURE,
      payload: error,
    });
  }
}

export function* activityIdleWatcher() {
  yield takeLatest(types.ACTIVITY_MODIFY_IDLE_REQUEST, modifyActivityIdle);
  yield takeLatest(types.CLEAR_IDLE_REQUEST, clearActivityIdle);
}
