/* eslint-disable */
import { put, call, takeLatest, select } from "redux-saga/effects";
import * as api from "./service";
import * as types from "./types";

//get workstreams
function* getWorkStreamsSaga(action) {
  try {
    const result = yield call(api.getWorkStreams, action.payload);
    yield put({
      type: types.GET_WORKSTREAMS_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_WORKSTREAMS_FAILURE,
      payload: error,
    });
  }
}

//add project
function* addWorkstreamSaga(action) {
  try {
    const result = yield call(api.addWorkstream, action.payload);
    yield put({
      type: types.POST_WORKSTREAM_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.POST_WORKSTREAM_FAILURE,
      payload: error,
    });
  }
}

//edit project
function* editWorkstreamSaga(action) {
  try {
    const result = yield call(api.editWorkstream, action.payload);
    yield put({
      type: types.EDIT_WORKSTREAM_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.EDIT_WORKSTREAM_FAILURE,
      payload: error,
    });
  }
}

//activate workstream
function* activateWorkStreamSaga(action) {
  try {
    //;
    const result = yield call(api.activateWorkStream, action.payload);
    yield put({
      type: types.ACTIVATE_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.ACTIVATE_FAILURE,
      payload: error,
    });
  }
}

//de-activate workstream
function* deActivateWorkStreamSaga(action) {
  try {
    const result = yield call(api.deActivateStream, action.payload);
    yield put({
      type: types.DE_ACTIVATE_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.DE_ACTIVATE_FAILURE,
      payload: error,
    });
  }
}

//quickstart workstream
function* quickstartWorkstream(action) {
  try {
    const result = yield call(api.quickstartWorkstream, action.payload);
    yield put({
      type: types.WORKSTREAM_QUICKSTART_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.WORKSTREAM_QUICKSTART_FAILURE,
      payload: error,
    });
  }
}

export function* workStreamWatcher() {
  yield takeLatest(types.GET_WORKSTREAMS_REQUEST, getWorkStreamsSaga);
  yield takeLatest(types.POST_WORKSTREAM_REQUEST, addWorkstreamSaga);
  yield takeLatest(types.EDIT_WORKSTREAM_REQUEST, editWorkstreamSaga);
  yield takeLatest(types.ACTIVATE_REQUEST, activateWorkStreamSaga);
  yield takeLatest(types.DE_ACTIVATE_REQUEST, deActivateWorkStreamSaga);
  yield takeLatest(types.WORKSTREAM_QUICKSTART_REQUEST, quickstartWorkstream);
}
