/* eslint-disable */
import { put, call, takeLatest, select } from "redux-saga/effects";
import * as types from "./types";

//check
function* checkClientSaga(action) {
  try {
    yield put({
      type: types.CHECK_CLIENT_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_CLIENT_FAILURE,
      payload: error,
    });
  }
}

//check
function* checkDepartmentSaga(action) {
  try {
    yield put({
      type: types.CHECK_DEPARTMENT_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_DEPARTMENT_FAILURE,
      payload: error,
    });
  }
}

//check
function* checkMemberSaga(action) {
  try {
    yield put({
      type: types.CHECK_MEMBER_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_MEMBER_FAILURE,
      payload: error,
    });
  }
}

//check
function* checkProjectSaga(action) {
  try {
    yield put({
      type: types.CHECK_PROJECT_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_PROJECT_FAILURE,
      payload: error,
    });
  }
}

//check
function* check_PMO_DeliverableSaga(action) {
  try {
    yield put({
      type: types.CHECK_PMO_DELIVERABLE_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_PMO_DELIVERABLE_FAILURE,
      payload: error,
    });
  }
}

//check
function* check_PMO_ProjectSaga(action) {
  try {
    yield put({
      type: types.CHECK_PMO_PROJECT_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_PMO_PROJECT_FAILURE,
      payload: error,
    });
  }
}

//check
function* check_PMO_ProgramSaga(action) {
  try {
    yield put({
      type: types.CHECK_PMO_PROGRAM_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_PMO_PROGRAM_FAILURE,
      payload: error,
    });
  }
}

//check
function* check_PMO_PortfolioSaga(action) {
  try {
    yield put({
      type: types.CHECK_PMO_PORTFOLIO_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_PMO_PORTFOLIO_FAILURE,
      payload: error,
    });
  }
}

//check
function* checkProgramSaga(action) {
  try {
    yield put({
      type: types.CHECK_PROGRAM_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_PROGRAM_FAILURE,
      payload: error,
    });
  }
}

//check
function* checkPorfolioSaga(action) {
  try {
    yield put({
      type: types.CHECK_PORTFOLIO_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_PORTFOLIO_FAILURE,
      payload: error,
    });
  }
}

//clear-all workstream
function* clearAllSaga(action) {
  try {
    yield put({
      type: types.CLEAR_ALL_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.CLEAR_ALL_FAILURE,
      payload: error,
    });
  }
}

//check-all members
function* checkAllMembers(action) {
  try {
    yield put({
      type: types.CHECK_ALL_MEMBER_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_ALL_MEMBER_FAILURE,
      payload: error,
    });
  }
}

//un-check-all members
function* unCheckAllMembers(action) {
  try {
    yield put({
      type: types.UN_CHECK_ALL_MEMBER_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.UN_CHECK_ALL_MEMBER_FAILURE,
      payload: error,
    });
  }
}

//modify Template
function* modifyTemplate(action) {
  try {
    yield put({
      type: types.MODIFY_TEMPLATE_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.MODIFY_TEMPLATE_FAILURE,
      payload: error,
    });
  }
}

//modify workstream
function* modifyWorkstream(action) {
  try {
    yield put({
      type: types.MODIFY_WORKSTREAM_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.MODIFY_WORKSTREAM_FAILURE,
      payload: error,
    });
  }
}

export function* filterDataWatcher() {
  yield takeLatest(types.CHECK_CLIENT_REQUEST, checkClientSaga);
  yield takeLatest(types.CHECK_DEPARTMENT_REQUEST, checkDepartmentSaga);
  yield takeLatest(types.CHECK_MEMBER_REQUEST, checkMemberSaga);
  yield takeLatest(types.CHECK_PROJECT_REQUEST, checkProjectSaga);
  yield takeLatest(types.CHECK_PROGRAM_REQUEST, checkProgramSaga);
  yield takeLatest(types.CHECK_PORTFOLIO_REQUEST, checkPorfolioSaga);
  yield takeLatest(types.CLEAR_ALL_REQUEST, clearAllSaga);
  yield takeLatest(types.CHECK_ALL_MEMBER_REQUEST, checkAllMembers);
  yield takeLatest(types.UN_CHECK_ALL_MEMBER_REQUEST, unCheckAllMembers);
  yield takeLatest(
    types.CHECK_PMO_DELIVERABLE_REQUEST,
    check_PMO_DeliverableSaga
  );
  yield takeLatest(types.CHECK_PMO_PROJECT_REQUEST, check_PMO_ProjectSaga);
  yield takeLatest(types.CHECK_PMO_PROGRAM_REQUEST, check_PMO_ProgramSaga);
  yield takeLatest(types.CHECK_PMO_PORTFOLIO_REQUEST, check_PMO_PortfolioSaga);
  yield takeLatest(types.MODIFY_TEMPLATE_REQUEST, modifyTemplate);
  yield takeLatest(types.MODIFY_WORKSTREAM_REQUEST, modifyWorkstream);
}
