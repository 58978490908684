import * as types from "./types";

const initState = {
  data: [],
  logs: [],
};

const filterDataReducer = (state = initState, action) => {
  switch (action.type) {
    case types.ACTIVITY_MODIFY_IDLE_REQUEST:
      return {
        ...state,
      };
    case types.ACTIVITY_MODIFY_IDLE_SUCCESS:
      let obj = state.data.find((el) => el._id == action.payload.data._id);
      return {
        ...state,
        data: obj
          ? state.data.map((el) =>
              el._id == action.payload.data._id ? action.payload.data : el
            )
          : [...state.data, action.payload.data],
        logs: [
          ...state.logs,
          { ...action.payload.log, ref: action.payload.data._id },
        ],
      };
    case types.ACTIVITY_MODIFY_IDLE_FAILURE:
      return {
        ...state,
      };

    case types.CLEAR_IDLE_REQUEST:
      return {
        ...state,
      };
    case types.CLEAR_IDLE_SUCCESS:
      return {
        ...state,
        data: [],
        logs: [],
      };
    case types.CLEAR_IDLE_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default filterDataReducer;
