import { fork, all } from "redux-saga/effects";
import { authWatcher } from "./auth/saga";
import { adminWatcher } from "./admin/saga";
import { teamWatcher } from "./teams/saga";
import { clientWatcher } from "./clients/saga";
import { workStreamWatcher } from "./workStream/saga";
import { activitiesWatcher } from "./activities/saga";
import { filterDataWatcher } from "./FilterData/saga";
import { reportingWatcher } from "./Reporting/saga";
import { TemplateWorkStreamWatcher } from "./templates/saga";
import { pmoActivitiesWatcher } from "./pmo/saga";
import { socketWatcherSaga } from "./socket/saga";
import { activityIdleWatcher } from "./ActivityIdle/saga";

export default function* rootSaga() {
  yield all([fork(authWatcher)]);
  yield all([fork(adminWatcher)]);
  yield all([fork(teamWatcher)]);
  yield all([fork(clientWatcher)]);
  yield all([fork(workStreamWatcher)]);
  yield all([fork(filterDataWatcher)]);
  yield all([fork(activitiesWatcher)]);
  yield all([fork(reportingWatcher)]);
  yield all([fork(TemplateWorkStreamWatcher)]);
  yield all([fork(pmoActivitiesWatcher)]);
  yield all([fork(socketWatcherSaga)]);
  yield all([fork(activityIdleWatcher)]);
}
