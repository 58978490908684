import axios from "../../api/axiosConfig";

//get workstreams
export const getActivities = (data) => {
  return axios.get(`/activity/get-activities/${data}`);
};

//add project
export const addActivity = (data) => {
  return axios.post(`/activity/add-activity/${data.id}`, data.data);
};

//convert type
export const convertType = (data) => {
  return axios.patch(`/activity/edit-type/${data.id}`, data.data);
};

//delete activity
export const deleteActivity = (data) => {
  return axios.delete(`/activity/delete-activity/${data}`);
};

//edit activity
export const editActivity = (data) => {
  return axios.patch(`/activity/edit-activity/${data.id}`, data.data);
};

//add log
export const addLog = (data) => {
  return axios.post(`/activity/add-log/${data.id}`, data.data);
};

//get logs
export const getLogs = (data) => {
  return axios.get(`/activity/get-logs/${data}`);
};

//get activity client
export const getActivityClient = (data) => {
  return axios.get(`/activity/get-activity-client/${data?.data}/${data?.type}`);
};

//get activity member
export const getActivityMember = (data) => {
  return axios.get(
    `/activity/get-activity-by-member/${data?.id}/${data?.data}`
  );
};

//upload media
export const uploadMedia = (data) => {
  return axios.patch(`/activity/upload-media/${data.id}`, data.data);
};

//delete media
export const deleteMedia = (data) => {
  return axios.patch(`/activity/delete-media`, data);
};

//my activities
export const myActivities = (data) => {
  return axios.get(`/activity/my-activities/${data}`);
};

//get media
export const getMedia = (data) => {
  return axios.get(`/activity/get-media/${data}`);
};

//get media by id
export const getMediaById = (data) => {
  return axios.get(`/activity/get-media-byId/${data}`);
};

//get archive activities
export const getArchivedActivities = (data) => {
  return axios.post(`/activity/get-archived-activities`, data);
};

//get activities by ws
export const getActivitiesByWorkstreams = (data) => {
  return axios.patch(`/activity/get-activities-by-workstreams`, data);
};

//swap activities
export const swapActivities = (data) => {
  return axios.patch(`/activity/swap-activities`, data);
};

//get logs
export const getLogsByWorkstreams = (data) => {
  return axios.patch(`/activity/get-logs-by-workstreams`, data);
};

//get member logs
export const getLogsByMember = (data) => {
  return axios.patch(`/activity/get-logs-by-members`, data);
};

//edit all activity
export const editAllActivity = (data) => {
  return axios.patch(`/activity/edit-all-activity`, data);
};
