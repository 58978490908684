export const GET_ACTIVITIES_REQUEST = "GET_ACTIVITIES_REQUEST";
export const GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS";
export const GET_ACTIVITIES_FAILURE = "GET_ACTIVITIES_FAILURE";

export const POST_ACTIVITY_REQUEST = "POST_ACTIVITY_REQUEST";
export const POST_ACTIVITY_SUCCESS = "POST_ACTIVITY_SUCCESS";
export const POST_ACTIVITY_FAILURE = "POST_ACTIVITY_FAILURE";

export const CONVERT_TYPE_REQUEST = "CONVERT_TYPE_REQUEST";
export const CONVERT_TYPE_SUCCESS = "CONVERT_TYPE_SUCCESS";
export const CONVERT_TYPE_FAILURE = "CONVERT_TYPE_FAILURE";

export const DELETE_ACTIVITY_REQUEST = "DELETE_ACTIVITY_REQUEST";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAILURE = "DELETE_ACTIVITY_FAILURE";

export const EDIT_ACTIVITY_REQUEST = "EDIT_ACTIVITY_REQUEST";
export const EDIT_ACTIVITY_SUCCESS = "EDIT_ACTIVITY_SUCCESS";
export const EDIT_ACTIVITY_FAILURE = "EDIT_ACTIVITY_FAILURE";

export const ADD_LOG_REQUEST = "ADD_LOG_REQUEST";
export const ADD_LOG_SUCCESS = "ADD_LOG_SUCCESS";
export const ADD_LOG_FAILURE = "ADD_LOG_FAILURE";

export const GET_LOG_REQUEST = "GET_LOG_REQUEST";
export const GET_LOG_SUCCESS = "GET_LOG_SUCCESS";
export const GET_LOG_FAILURE = "GET_LOG_FAILURE";

export const FILTER_ACTIVITY_REQUEST = "FILTER_ACTIVITY_REQUEST";
export const FILTER_ACTIVITY_SUCCESS = "FILTER_ACTIVITY_SUCCESS";
export const FILTER_ACTIVITY_FAILURE = "FILTER_ACTIVITY_FAILURE";

export const CLEAR_TABLE_REQUEST = "CLEAR_TABLE_REQUEST";
export const CLEAR_TABLE_SUCCESS = "CLEAR_TABLE_SUCCESS";
export const CLEAR_TABLE_FAILURE = "CLEAR_TABLE_FAILURE";

export const GET_CLIENT_ACT_REQUEST = "GET_CLIENT_ACT_REQUEST";
export const GET_CLIENT_ACT_SUCCESS = "GET_CLIENT_ACT_SUCCESS";
export const GET_CLIENT_ACT_FAILURE = "GET_CLIENT_ACT_FAILURE";

export const GET_MEMBER_ACT_REQUEST = "GET_MEMBER_ACT_REQUEST";
export const GET_MEMBER_ACT_SUCCESS = "GET_MEMBER_ACT_SUCCESS";
export const GET_MEMBER_ACT_FAILURE = "GET_MEMBER_ACT_FAILURE";

export const UPLOAD_MEDIA_REQUEST = "UPLOAD_MEDIA_REQUEST";
export const UPLOAD_MEDIA_SUCCESS = "UPLOAD_MEDIA_SUCCESS";
export const UPLOAD_MEDIA_FAILURE = "UPLOAD_MEDIA_FAILURE";

export const DELETE_MEDIA_REQUEST = "DELETE_MEDIA_REQUEST";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const DELETE_MEDIA_FAILURE = "DELETE_MEDIA_FAILURE";

export const MY_ACTIVITIES_REQUEST = "MY_ACTIVITIES_REQUEST";
export const MY_ACTIVITIES_SUCCESS = "MY_ACTIVITIES_SUCCESS";
export const MY_ACTIVITIES_FAILURE = "MY_ACTIVITIES_FAILURE";

export const CLEAR_MY_ACTIVITIES_REQUEST = "CLEAR_MY_ACTIVITIES_REQUEST";
export const CLEAR_MY_ACTIVITIES_SUCCESS = "CLEAR_MY_ACTIVITIES_SUCCESS";
export const CLEAR_MY_ACTIVITIES_FAILURE = "CLEAR_MY_ACTIVITIES_FAILURE";

export const GET_MEDIA_REQUEST = "GET_MEDIA_REQUEST";
export const GET_MEDIA_SUCCESS = "GET_MEDIA_SUCCESS";
export const GET_MEDIA_FAILURE = "GET_MEDIA_FAILURE";

export const GET_MEDIA_BYID_REQUEST = "GET_MEDIA_BYID_REQUEST";
export const GET_MEDIA_BYID_SUCCESS = "GET_MEDIA_BYID_SUCCESS";
export const GET_MEDIA_BYID_FAILURE = "GET_MEDIA_BYID_FAILURE";

export const SET_CARD_DATA_REQUEST = "SET_CARD_DATA_REQUEST";
export const SET_CARD_DATA_SUCCESS = "SET_CARD_DATA_SUCCESS";
export const SET_CARD_DATA_FAILURE = "SET_CARD_DATA_FAILURE";

export const GET_ARCHIVE_ACTIVITIES_REQUEST = "GET_ARCHIVE_ACTIVITIES_REQUEST";
export const GET_ARCHIVE_ACTIVITIES_SUCCESS = "GET_ARCHIVE_ACTIVITIES_SUCCESS";
export const GET_ARCHIVE_ACTIVITIES_FAILURE = "GET_ARCHIVE_ACTIVITIES_FAILURE";

export const GET_ACTIVITIES_BY_WORKSTREAMS_REQUEST =
  "GET_ACTIVITIES_BY_WORKSTREAMS_REQUEST";
export const GET_ACTIVITIES_BY_WORKSTREAMS_SUCCESS =
  "GET_ACTIVITIES_BY_WORKSTREAMS_SUCCESS";
export const GET_ACTIVITIES_BY_WORKSTREAMS_FAILURE =
  "GET_ACTIVITIES_BY_WORKSTREAMS_FAILURE";

export const SWAP_ACTIVITIES_REQUEST = "SWAP_ACTIVITIES_REQUEST";
export const SWAP_ACTIVITIES_SUCCESS = "SWAP_ACTIVITIES_SUCCESS";
export const SWAP_ACTIVITIES_FAILURE = "SWAP_ACTIVITIES_FAILURE";

export const IS_MY_ACTIVITIES_REQUEST = "IS_MY_ACTIVITIES_REQUEST";
export const IS_MY_ACTIVITIES_SUCCESS = "IS_MY_ACTIVITIES_SUCCESS";
export const IS_MY_ACTIVITIES_FAILURE = "IS_MY_ACTIVITIES_FAILURE";

export const EDIT_ACTIVITY_STATE_REQUEST = "EDIT_ACTIVITY_STATE_REQUEST";
export const EDIT_ACTIVITY_STATE_SUCCESS = "EDIT_ACTIVITY_STATE_SUCCESS";
export const EDIT_ACTIVITY_STATE_FAILURE = "EDIT_ACTIVITY_STATE_FAILURE";

export const GET_WORKSTREAM_LOG_REQUEST = "GET_WORKSTREAM_LOG_REQUEST";
export const GET_WORKSTREAM_LOG_SUCCESS = "GET_WORKSTREAM_LOG_SUCCESS";
export const GET_WORKSTREAM_LOG_FAILURE = "GET_WORKSTREAM_LOG_FAILURE";

export const CLEAR_LOG_REQUEST = "CLEAR_LOG_REQUEST";
export const CLEAR_LOG_SUCCESS = "CLEAR_LOG_SUCCESS";
export const CLEAR_LOG_FAILURE = "CLEAR_LOG_FAILURE";

export const ADD_DEPENDENCY_REQUEST = "ADD_DEPENDENCY_REQUEST";
export const ADD_DEPENDENCY_SUCCESS = "ADD_DEPENDENCY_SUCCESS";
export const ADD_DEPENDENCY_FAILURE = "ADD_DEPENDENCY_FAILURE";

export const GET_MEMBER_LOG_REQUEST = "GET_MEMBER_LOG_REQUEST";
export const GET_MEMBER_LOG_SUCCESS = "GET_MEMBER_LOG_SUCCESS";
export const GET_MEMBER_LOG_FAILURE = "GET_MEMBER_LOG_FAILURE";

export const EDIT_ALL_ACTIVITY_REQUEST = "EDIT_ALL_ACTIVITY_REQUEST";
export const EDIT_ALL_ACTIVITY_SUCCESS = "EDIT_ALL_ACTIVITY_SUCCESS";
export const EDIT_ALL_ACTIVITY_FAILURE = "EDIT_ALL_ACTIVITY_FAILURE";
